import { styled } from "styled-components";
import { sizes } from "../constants/devices.js";

const ArticleHeader = styled.h2``;

const ArticleBody = styled.p`
	font-family: "Noto Sans", sans-serif;
	font-weight: 400;
	font-size: 2rem;
	text-align: left;
	margin: 2rem auto 2rem auto;

	@media only screen and (max-width: ${sizes.mobileL}) {
		font-size: 1.6rem;
	}
`;

const ArticleList = styled.li`
	font-family: "Noto Sans", sans-serif;
	font-weight: 400;
	font-size: 2rem;
	text-align: left;
	margin-left: 2rem;
	list-style: disc;

	@media only screen and (max-width: ${sizes.mobileL}) {
		font-size: 1.6rem;
	}
`;

const ArticleSubHeading = styled.h2`
	font-family: "piepie", sans-serif;
	font-weight: 500;
	font-size: 5rem;
	margin: 0 auto 2rem auto;

	@media only screen and (max-width: ${sizes.mobileL}) {
		font-size: 3.2rem;
	}
`;

const ArticleLink = styled.a`
	text-decoration: underline;
`;

const Go = styled.p`
	font-family: "Noto Sans", sans-serif;
	font-weight: 500;
	font-size: 2rem;
	text-align: right;
	text-decoration: underline;
	margin-top: 6.4rem;
	line-height: 1.2rem;

	@media only screen and (max-width: ${sizes.mobileL}) {
		font-size: 1.6rem;
	}
`;

export {
	ArticleBody,
	ArticleSubHeading,
	ArticleHeader,
	ArticleList,
	ArticleLink,
	Go,
};
