import "./Manifesto.css";

// MENU //

// FRAMER MOTION //
import { useInView } from "react-intersection-observer";
import { motion, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";

const ManifestoBlack = ({ images, handlePlayerChange, selectedPlayer }) => {
	/// BLOCKS ///
	const HereIsWhat = () => {
		const ref = useRef(null);
		const { scrollYProgress } = useScroll({ target: ref });
		const avocadoToast = useTransform(scrollYProgress, [0, 1], [-100, 200]);

		return (
			<div className="manifesto-black_paragraph">
				<div ref={ref} style={{ zIndex: "1" }}>
					<motion.div className="second_manifesto" style={{}}>
						<div className="manifesto-black_paragraph-title">
							Here’s what we believe:
						</div>
						<p>
							Exercising caution with any new technology is necessary. But
							choosing to be cautiously optimistic will open up a world of
							possibilities for our industry. The only way to understand the
							potential of new tech is to dive in head first and learn.
						</p>
					</motion.div>
				</div>
				<motion.img
					src={images.avocado_toast}
					alt={images.avocado_toast}
					style={{
						position: "absolute",
						width: "80%",
						right: "0",
						opacity: ".2",
						zIndex: "0",
						userDrag: "none",
						WebkitUserDrag: "none",
						userSelect: "none",
						MozUserSelect: "none",
						WebkitUserSelect: "none",
						msUserSelect: "none",
						height: "auto",
						y: avocadoToast,
					}}
				/>
			</div>
		);
	};

	const Avocado = () => {
		/// BLOCKS ///
		const ref = useRef(null);
		const { scrollYProgress } = useScroll({ target: ref });
		const avocadoLeft = useTransform(scrollYProgress, [0, 1], [-250, 600]);

		return (
			<div>
				<div ref={ref} style={{ zIndex: "1" }}></div>
				<motion.img
					src={images.avocado_3}
					alt={images.avocado_3}
					className="avocado_image_left"
					style={{
						y: avocadoLeft,
					}}
				/>
			</div>
		);
	};

	const Plenty = () => {
		const ref = useRef(null);
		const { scrollYProgress } = useScroll({ target: ref });
		const avocadoHalf = useTransform(scrollYProgress, [0, 1], [-100, 200]);

		return (
			<div className="manifesto-black_paragraph forty-pt four_manifesto">
				<motion.div style={{}} ref={ref}>
					<p>
						There are plenty of things about AI we don’t know, but one thing we
						do:
					</p>
					<p>
						AI is not going to take your next job, but a person who knows how to
						use it just might.
					</p>
				</motion.div>
				<motion.img
					src={images.avocado_1}
					alt={images.avocado_1}
					className="avocado_image_right"
					style={{
						rotate: "33deg",
						y: avocadoHalf,
					}}
				/>
			</div>
		);
	};

	/// ANIMATIONS ///
	/// INVIEW ///
	const [p1, inView1] = useInView({
		threshold: 0.5,
		triggerOnce: false,
	});

	const [p3, inView3] = useInView({
		threshold: 0.5,
		triggerOnce: false,
	});
	/// VARIANTS ///

	const variants = {
		visible: { opacity: 1, scale: 1, y: 0 },
		hidden: {
			opacity: 0,
		},
	};

	/// QUOTES ANIMATION ///
	const quotesContainer = useRef(null);
	const { scrollYProgress } = useScroll({
		target: quotesContainer,
		offset: ["0 0", "0.1 1"],
	});

	const hMovement = useTransform(scrollYProgress, [0, 1], [0, 250], {
		clamp: true,
	});
	const hMovementP = useTransform(scrollYProgress, [0, 1], [0, 350], {
		clamp: true,
	});
	const hMovementLeftToRight = useTransform(
		scrollYProgress,
		[0, 1],
		[0, -250],
		{ clamp: true }
	);
	const hMovementLeftToRightP = useTransform(
		scrollYProgress,
		[0, 1],
		[0, -150],
		{ clamp: true }
	);

	const hOpacity = useTransform(scrollYProgress, [0, 1], [1, 0]);

	return (
		<section className="manifesto-black" ref={quotesContainer}>
			{/*// QUOTES //*/}
			<div className="srollParent">
				<div className="manifesto_quotes_container">
					<div className="manifesto-black_quotes">
						<div className="viewInDesktop">
							<motion.div
								className="manifesto-black_quote-1"
								style={{ left: hMovementP, opacity: hOpacity }}
							>
								“IT WILL TAKE
							</motion.div>
							<motion.div
								className="manifesto-black_quote-2"
								style={{ left: hMovementLeftToRightP, opacity: hOpacity }}
							>
								OUR JOBS”
							</motion.div>
						</div>
						<div className="viewInDesktop">
							<motion.div
								className="manifesto-black_quote-3"
								style={{ left: hMovement, opacity: hOpacity }}
							>
								“IT’S THE AI
							</motion.div>
							<motion.div
								className="manifesto-black_quote-4"
								style={{ left: hMovementLeftToRight, opacity: hOpacity }}
							>
								APOCALYPSE”
							</motion.div>
						</div>
						{/* Quotes for mobile ===================================== */}
						<div className="viewInMobile">
							<motion.div
								className="manifesto-black_quote-1"
								style={{ left: hMovementP, opacity: hOpacity }}
							>
								“IT WILL
							</motion.div>
							<motion.div
								className="manifesto-black_quote-2"
								style={{ left: hMovementLeftToRightP, opacity: hOpacity }}
							>
								TAKE OUR
							</motion.div>
							<motion.div
								className="manifesto-black_quote-2"
								style={{ left: hMovementLeftToRightP, opacity: hOpacity }}
							>
								JOBS”
							</motion.div>
						</div>
						<div className="viewInMobile">
							<motion.div
								className="manifesto-black_quote-3"
								style={{ left: hMovement, opacity: hOpacity }}
							>
								“IT’S THE AI
							</motion.div>
							<motion.div
								className="manifesto-black_quote-4"
								style={{ left: hMovementLeftToRight, opacity: hOpacity }}
							>
								APOCALYPSE”
							</motion.div>
						</div>
					</div>
				</div>
				{/*// PARAGRAPHS //*/}
				<Avocado />
				<motion.div
					style={{}}
					className="manifesto-black_paragraph first_manifesto"
					animate={inView1 ? "visible" : "hidden"}
					variants={variants}
					transition={{ duration: 1, ease: "easeOut" }}
					ref={p1}
				>
					<p>
						While we’re all for tossing predictions around like avocado toast
						recipes on Instagram, what if the truth is somewhere between the
						fantasy and the fear mongering?
					</p>
				</motion.div>
			</div>

			<HereIsWhat />

			<motion.div
				style={{ maxWidth: "660px", margin: "auto" }}
				className="manifesto-black_paragraph"
				animate={inView3 ? "visible" : "hidden"}
				variants={variants}
				transition={{ duration: 1, ease: "easeOut" }}
				ref={p3}
			>
				<div className="manifesto-black_paragraph-big">
					And to learn,<br className="hidden_on_phone"></br> we must PLAY.
				</div>
			</motion.div>

			<Plenty />
		</section>
	);
};
export { ManifestoBlack };
